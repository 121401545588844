<template></template>
<script>
  import sdk from "@/store/sdk";

  export default {
    name:'share',
    props: ['val'],
    mounted() {
      setTimeout(() => {
        this.share();
      }, 1000);
    },
    methods: {
      share() {
        // 分享朋友圈
        let val = this.val
        // let ip1 = 'http://prod.gitok.com/h5';
        // let url = 'http://prod.gitok.com/h5';
        let ip1 = window.location.href.split('list')[0]
        let url = window.location.href.split('list')[0]
        let dataForWeixin = {
          title: val.title, //分享标题
          desc: val.desc, //分享内容
          linkurl: url, //分享链接
          img: val.img, //分享内容显示的图片(图片必须是正方形的链接)
        };
        //sdk.getJSSDK(encodeURIComponent(ip1), dataForWeixin);
        sdk.getJSSDK(ip1, dataForWeixin);
      },
    },
  };
</script>

<style>
</style>
