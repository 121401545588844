import wx from 'weixin-js-sdk'
import {wxShare} from "@/api/common";


//要用到微信API
function getJSSDK(url, dataForWeixin) {
  // 调用后台接口换取参数
  wxShare({url}).then(res => {
    // console.log(res, url)
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: 'wx35150d594157e090', // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      // jsApiList: jsApiList // 必填，需要使用的JS接口列表
      jsApiList: [ //需要调用的JS接口列表
        'updateAppMessageShareData', //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        'updateTimelineShareData', //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
      ]
    })
    wx.ready(function () {
      wx.updateAppMessageShareData({
        title: dataForWeixin.title, // 分享标题
        desc: dataForWeixin.desc, // 分享描述
        link: dataForWeixin.linkurl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
        imgUrl: dataForWeixin.img, // 分享图标
        success: function () {
          console.log('设置成功')
        }
      })
      // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
      wx.updateTimelineShareData({
        title: dataForWeixin.title,
        link: dataForWeixin.linkurl,
        imgUrl: dataForWeixin.img,
        success: function () {
          console.log('设置成功')
        }
      })
    })
    wx.error(function (result) {
      console.log(JSON.stringify(result) + "微信验证失败");
    });
  })
}

export default {
  // 获取JSSDK
  getJSSDK: getJSSDK
}

